<template>
  <div class="page">
    <div class="photo"></div>
    <div class="sport">
      <font-awesome-icon icon="fa-solid fa-futbol" /> {{ match.sport }}
      {{ match.max_players / 2 }} VS
      {{ match.max_players / 2 }}
    </div>
    <div class="direction">
      <font-awesome-icon icon="fa-solid fa-location-dot" />
      {{ match.direction }}, {{ match.district }}
    </div>
    <div class="date">
      <font-awesome-icon icon="fa-solid fa-calendar-days" />
      {{ match.match_date }} {{ match.match_hour }}hrs
    </div>
    <div class="creator">
      <font-awesome-icon icon="fa-solid fa-user" /> {{ match.name }}
      {{ match.lname }}
    </div>
    <div class="divider"></div>
    <div class="subtitle">
      Jugadores ({{ players_lenght }}/{{ match.max_players }})
    </div>
    <div class="rows">
      <div class="players">
        <div v-for="player in players" :key="player">
          <img class="image" src="@/assets/luchito.png" /> {{ player.name }}
          {{ player.lname }}
        </div>
      </div>
      <button
        class="sc-btn"
        @click="join"
        v-if="match.max_players > players_lenght && !assist"
      >
        <span>Unirse</span>
      </button>
      <button
        class="sc-btn"
        @click="retire"
        v-if="assist && match.user_uid !== uid"
      >
        <span>Retirarse</span>
      </button>
      <button class="sc-btn" @click="show = true" v-if="match.user_uid === uid && match.max_players > players_lenght">
        <span>Agregar</span>
      </button>
      <span v-if="match.max_players <= players_lenght && (!assist || match.user_uid === uid)"
        >El partido llegó a su cupo máximo</span
      >
    </div>
    <the-modal :show="show" @close="show = false">
      <template #header>
        <h6>Seleccionar amigos</h6>
      </template>
      <template #body>
        <div>
          <table>
            <tbody>
              <tr v-for="friend in friends" :key="friend">
                <td>
                  <input
                    type="checkbox"
                    @change="handleChange(friend.friend_uid, $event)"
                  />
                </td>
                <td>{{ friend.name }} {{ friend.lname }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #footer>
        <button
          class="sc-btn"
          @click="(show = false) && (friends_selected = [])"
        >
          <span>Regresar</span>
        </button>
        <button class="sc-btn" @click="updateAssistants(friends_selected)">
          <span>Guardar</span>
        </button>
      </template>
    </the-modal>
  </div>
</template>

<script>
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";

export default {
  data() {
    return {
      match: "",
      players: [],
      players_lenght: "",
      uid: "",
      assist: false,
      show: false,
      friends_selected: [],
      friends: [],
    };
  },

  mounted() {
    this.loadMatch();
    this.loadPlayers();
    this.loadFriends();
    this.getUID();
  },

  methods: {
    getUID() {
      if (sessionStorage.getItem("FirebaseToken")) {
        const token = sessionStorage.getItem("FirebaseToken");
        var decoded = VueJwtDecode.decode(token);
        this.uid = decoded.user_id;
      }
    },

    loadMatch() {
      const url = `${process.env.VUE_APP_VERSUS_API}/match/info/${this.$route.params.match_id}`;

      axios
        .get(url)
        .then((res) => {
          this.match = res.data;
          console.log(this.match);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadPlayers() {
      const url = `${process.env.VUE_APP_VERSUS_API}/match/assistants/${this.$route.params.match_id}`;

      await axios
        .get(url)
        .then((res) => {
          this.players = res.data;
          this.players_lenght = this.players.length;
          console.log(this.players);
        })
        .catch((error) => {
          console.log(error);
        });

      for (var i = 0; i < this.players_lenght; i++) {
        if (this.players[i].user_uid === this.uid) {
          this.assist = true;
        }
      }
    },

    loadFriends() {
      fetch(`${process.env.VUE_APP_VERSUS_API}/friend/`, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
          },
        })
        .then((response) => response.json())
        .then((data) => (this.friends = data));
    },

    join() {
      const url = `${process.env.VUE_APP_VERSUS_API}/match`;

      const body = {
        match_id: this.$route.params.match_id,
        user_uid: this.uid,
      };
      
      axios
        .put(url, body)
        .then((res) => {
          console.log(res);
          this.assist = true;
          this.loadMatch();
          this.loadPlayers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    retire() {
      const body = {
        match_id: this.$route.params.match_id,
        user_uid: this.uid,
      };
      const url = `${process.env.VUE_APP_VERSUS_API}/match/${body.match_id}/${body.user_uid}`;

      axios
        .delete(url)
        .then((res) => {
          console.log(res);
          this.assist = false;
          this.loadMatch();
          this.loadPlayers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleChange(friend_id, event) {
      if (event.target.checked) {
        this.friends_selected.push(friend_id);
        console.log(this.friends_selected);
      } else {
        for (var i = 0; i < this.friends_selected.length; i++) {
          if (this.friends_selected[i] === friend_id) {
            for (var j = i; j < this.friends_selected.length - 1; j++) {
              this.friends_selected[j] = this.friends_selected[j + 1];
            }
            this.friends_selected.pop();
          }
        }
        console.log(this.friends_selected);
      }
    },

    updateAssistants(friends) {
      for (var i = 0; i < friends.length; i++) {
        const body = {
          user_uid: friends[i],
          match_id: this.$route.params.match_id,
        };
        const url = `${process.env.VUE_APP_VERSUS_API}/match/`;

        axios
          .put(url, body)
          .then((res) => {
            console.log(res);
            this.loadPlayers
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.show = false;
      this.friends_selected = [];

      this.loadPlayers();
    },
  },
};
</script>

<style scoped>
.page {
  margin-right: auto;
  align-items: start;
  margin-top: 30px;
  margin-left: 25px;
}

.photo {
  width: 345px;
  height: 168px;
  left: 24px;
  top: 99px;
  background: #d9d9d9;
}

.divider {
  width: 335px;
  border: 1px solid #000000;
}

.sport {
  left: 68px;
  top: 279px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
}

.direction {
  left: 30px;
  top: 317px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #000000;
}

.date {
  left: 67px;
  top: 377px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;

  color: #000000;
}

.creator {
  height: 22px;
  left: 90px;
  top: 437px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
}

.subtitle {
  width: 140px;
  height: 22px;
  left: 20px;
  top: 505px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  color: #000000;
}

.players {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.rows {
  width: 330px;
  height: 36px;
  left: 29px;
  top: 538px;
  display: grid;
  grid-template-rows: 1fr;
  gap: 20px;
}

.image {
  width: 36px;
  border-radius: 100%;
}
</style>