<template>
  <div class="page">
    <div class="content">
      <div class="container-match">
        <div class="ads-v">
          <div class="ads-content">
            <h6>Ads</h6>
          </div>
        </div>
        <div class="profile">
          <img
            class="img-profile rounded-circle"
            src="@/assets/undraw_profile.svg"
            v-if="!user.image_profile"
          />
          <img
            class="img-profile rounded-circle"
            :src="user.image_profile"
            v-if="user.image_profile"
          />
          <h1 class="welcome" v-if="!user.nickname">{{ user.name }}</h1>
          <h1 class="welcome" v-if="user.nickname">{{ user.nickname }}</h1>
        </div>
        <div class="search-box">
          <form action="">
            <input
              class="sc-input"
              type="text"
              placeholder="¿Cuándo quieres jugar?"
            />
          </form>
        </div>
        <div class="pichanga-1">
          <h2>Tus Próximos Partidos</h2>
        </div>
        <div class="match next">
          <h6 v-show="my_matches.length === 0">No hay partidos pendientes..</h6>
          <div v-for="my_match in my_matches" :key="my_match">
            <div
              class="match-card"
              :style="{
                backgroundImage: `url(${require('@/assets/' +
                  my_match.sports_id +
                  '.png')})`,
              }"
            >
              <div class="info">
                <h2>{{ my_match.sport }}</h2>
                <h2>{{ my_match.direction }}</h2>
              </div>
              <button class="btn-card" @click="toMatch(my_match.match_id)">
                Ver Más
              </button>
            </div>
          </div>
        </div>
        <div class="pichanga-1">
          <h2>Cerca a ti...</h2>
        </div>
        <div class="match next">
          <h6 v-show="my_matches_nearby.length === 0">
            No hay partidos disponibles..
          </h6>
          <div
            v-for="my_match_nearby in my_matches_nearby"
            :key="my_match_nearby"
          >
            <div
              class="match-card"
              :style="{
                backgroundImage: `url(${require('@/assets/' +
                  my_match_nearby.sports_id +
                  '.png')})`,
              }"
            >
              <div class="info">
                <h2>{{ my_match_nearby.sport }}</h2>
                <h2>{{ my_match_nearby.direction }}</h2>
              </div>
              <button @click="toMatch(mmy_match_nearby.match_id)">
                Ver Más
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="ads-h">
        <div class="ads-content">
          <h6>Ads</h6>
        </div>
      </div>
      <div class="container-group">
        <div class="my-groups">
          <h2>Mis Grupos</h2>
        </div>
        <div class="groups-row">
            <ul>
              <li v-for="group in groups" :key="group">
                <div class="groups">
                  <img
                    class="img-group rounded-circle"
                    src="@/assets/undraw_profile.svg"
                  />
                  <h2>{{ group.group_name }}</h2>
                  <font-awesome-icon icon="fa-solid fa-chevron-down" />
                </div>
                <ul class="group-entries">
                  <li>
                    <router-link :to="'/group/' + group.group_id"
                      >Entrar al Grupo</router-link
                    >
                  </li>
                  <li><a href="#">Ver Jugadores</a></li>
                  <li><a href="#">Abandonar Grupo</a></li>
                </ul>
              </li>
            </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      uid: "",
      user: "",
      my_matches: [],
      groups: [],
      showModal: false,
      my_matches_nearby: [],
    };
  },

  mounted() {
    this.loadUser();
    this.loadMyMatches();
    this.loadGroups();
  },

  methods: {
    loadUser() {
      const url = `${process.env.VUE_APP_VERSUS_API}/user/`;

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
          },
        })
        .then((res) => {
          this.user = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadMyMatches() {
      const url = `${process.env.VUE_APP_VERSUS_API}/match/`;

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
          },
        })
        .then((res) => {
          this.my_matches = res.data;
          console.log(this.my_matches);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadNearbyMatches() {
      const url = `${process.env.VUE_APP_VERSUS_API}/match/nearby/`;

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
          },
        })
        .then((res) => {
          this.my_matches_nearby = res.data;
          console.log(this.my_matches_nearby);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadGroups() {
      const url = `${process.env.VUE_APP_VERSUS_API}/group/`;

      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
          },
        })
        .then((res) => {
          this.groups = res.data;
          console.log(this.groups);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toMatch(match_id) {
      this.$router.push("/match/" + match_id);
    },
  },
};
</script>



<style scoped>
.page {
  margin-top: 0px;
  margin-right: auto;
  align-items: start;
  margin-left: 20px;
}
li {
  list-style-type: none;
}
.welcome {
  height: 44px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;

  color: #000000;
}
span {
  cursor: pointer;
}

.search-box {
  width: 60%;
  height: 60px;
  margin-left: 20%;

  top: 176px;

  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.sc-input {
  margin: 10px;
  width: 90%;
}
.my-groups {
  width: 120px;
  height: 19px;
  left: 29px;
  margin-top: 10px;
  margin-bottom: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}

.group-entries {
  display: none;
}

.groups-row {
  display: flex;
  flex-direction: row;
  left: 15px;
  margin-top: 20px;
  gap: 20px;
}

.groups {
  display: flex;
  height: 50px;
  border-radius: 20px;
  margin-top: 0px;
  gap: 10px;
}

.match {
  display: flex;
  flex-direction: row;
  left: 19px;
  width: 80%;
  gap: 20px;
  overflow-x: scroll;
}

.ads-content {
  position: relative;
  width: 31px;
  height: 19px;
  top: 30px;
  margin-left: 40%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
}
.ads-h {
  width: 80%;
  height: 79px;
  margin-left: 30px;
  margin-top: 20px;
  background: #d9d9d9;
}
.ads-v {
  left: 15px;
  margin-top: 20px;
  background: #d9d9d9;
  visibility: hidden;
}

.content {
  width: 100%;
}

.pichanga-1 {
  margin-top: 20px;
}

.container-match {
  width: 100%;
}

.img-profile {
  height: 10rem;
  width: 10rem;
  margin-top: 5px;
}
.img-group {
  height: 2rem;
  width: 2rem;
}
.pichanga-1,
.match {
  margin-left: 30px;
}

.container-group {
  margin-left: 30px;
}

ul li a {
  display: block;
  width: auto;
  position: relative;
  background-color: white;
}

ul > li {
  list-style-type: none;
}

ul li ul {
  position: relative;
  left: 0px;
  display: none;
  width: auto;
}

ul li:hover > ul {
  display: block;
}

li a {
  height: 60px;
  display: block;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  color: rgb(4, 35, 89);
  background-color: bisque;
}

a {
  cursor: pointer;
}

.fa-chevron-down {
  padding: 6px;
  margin-left: auto;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .groups-row {
    display: flex;
    flex-wrap: wrap;
    left: 15px;
    margin-top: 20px;
    gap: 20px;
  }

  .search-box {
    margin-left: 20%;
  }
}
@media only screen and (min-width: 768px) {
  /* For PC : */
  .ads-h {
    display: none;
    width: 79px;
    height: 100%;
  }
  .ads-v {
    visibility: visible;
    width: 98%;
    height: 150px;
    margin-right: 20px;
  }
  .profile {
    display: none;
  }
  .search-box {
    display: none;
  }

  .pichanga-1 {
    margin-right: 16%;
  }
  .match {
    justify-content: center;
  }
  .groups-row > ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }


  .groups {
    display: flex;
    width: 300px;
    height: 50px;
    border-radius: 20px;
    border: 1px solid black;
    margin-top: 0px;
    gap: 10px;
  }
}
</style>