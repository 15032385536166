import { createApp } from 'vue'
import App from './App.vue'
import router from "./router.js";

import NavBar from './components/NavBar.vue';
import TheModal from './components/TheModal.vue'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faLocationDot, faUser, faCalendarDays, faFutbol, faStar, faBars, faChevronDown} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faLocationDot, faUser, faCalendarDays, faFutbol, faStar, faBars, faChevronDown)


const app = createApp(App)
app.use(router);
app.component('font-awesome-icon', FontAwesomeIcon);
app.component('nav-bar', NavBar)
app.component('the-modal', TheModal)

app.mount('#app')
