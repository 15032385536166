<template>
  <div class="page">
    <div class="content">
      <div>
        <img src="@/assets/luchito.png" />
      </div>
      <div class="user-info">
        <h3>{{ friend.name }} {{friend.lname}}</h3>
        <h6>{{ friend.nickname }}</h6>
      </div>
    <div class="user-info">
        <p>{{ friend.num_agree/friend.num_rated * 5}} <font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /></p>
        <span>Siguiendo</span>
    </div>
    </div>
    <div class="container-data">
      <!-- DataTales Example -->
      <div class="card mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Descripción</h6>
        </div>
        <p>
          {{friend.description}}
        </p>
      </div>
      <div class="card mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">
            Comentarios sobre el jugador
          </h6>
        </div>
        <div class="card-body divider" v-for="review in reviews" :key="review">
          <h6>{{review.name}} {{review.lname}}</h6>
          <div class="divider"></div>
          <p>
            {{review.review}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      friend: "",
      reviews: [],
    };
  },

  mounted() {
    this.loadInfo();
    this.loadReviews();
  },

  methods: {
    async loadInfo() {
    fetch(`${process.env.VUE_APP_VERSUS_API}/friend/${this.$route.params.id}`)
        .then((response) => response.json())
        .then((data) => (this.friend = data));

        console.log(this.friend)
    },

    loadReviews(){
      fetch(`${process.env.VUE_APP_VERSUS_API}/friend/reviews/${this.$route.params.id}`)
        .then((response) => response.json())
        .then((data) => (this.reviews = data));
    }

  },
};
</script>

<style scoped>
.content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.user-info{
    display: flex;
    flex-direction: column;
}

img {
  width: 30%;
  border-radius: 100%;
}

h3 {
  width: 100%;
}

.card {
  width: 65%;
  margin-left: 250px;
  margin-top: 20px;
}

.divider {
  border: 1px solid #000000;
}
</style>