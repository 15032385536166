<template>
  <div class="page">
    <h1>Crea tu Pichanga</h1>
    <div class="form-search">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01"
            >Dirección</label
          >
        </div>
        <input type="text" class="form-control" v-model="direction" />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01"
            >Distrito</label
          >
        </div>
        <input type="text" class="form-control" v-model="district" />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Fecha</label>
        </div>
        <input type="date" class="form-control" v-model="date" />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Hora</label>
        </div>
        <select
          class="custom-select"
          id="inputGroupSelect01"
          v-model="match_hour"
        >
          <option value="">Seleccionar una hora...</option>
          <option v-for="hour in hours" :key="hour" :value="hour" required>
            {{ hour }}hrs
          </option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Deporte</label>
        </div>
        <select
          class="custom-select"
          id="inputGroupSelect01"
          v-model="match_sport"
        >
          <option value="">Seleccionar un Deporte...</option>
          <option v-for="sport in sports" :key="sport" :value="sport.sports_id" required>
            {{ sport.sport }}
          </option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Max jugadores</label>
        </div>
        <select
          class="custom-select"
          id="inputGroupSelect01"
          v-model="max_players"
        >
          <option value="">¿Cuantos jugadores?</option>
          <option v-for="player in players" :key="player" :value="player" required>
            {{ player/2 }} VS {{player/2}}
          </option>
        </select>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" for="inputGroupSelect01">Lugar</label>
        </div>
        <input
          type="file"
          class="form-control"
          ref="inputFile"
          @change="uploadFile()"
          accept="image/*"
        />
      </div>
      <div class="rd-btn">
        <span>Modo:</span>
        <div>
          <input
            type="radio"
            name="selection"
            v-model="selection"
            value=1
          />
          <label>Público</label><br />
        </div>
        <div>
          <input
            type="radio"
            name="selection"
            v-model="selection"
            value=0
          />
          <label>Privado</label><br />
        </div>
      </div>
    </div>
    <div class="btn">
      <button class="sc-btn" @click="create">
        <span>Guardar</span>
      </button>
    </div>
    <the-modal :show="show" @close="show = false">
      <template #header>
        <h6>Seleccionar amigos</h6>
      </template>
      <template #body>
        <div>
          <table>
            <tbody>
              <tr v-for="friend in friends" :key="friend">
                <td>
                  <input type="checkbox" 
                  @change="handleChange(friend.friend_uid, $event)"/>
                </td>
                <td>{{ friend.name }} {{friend.lname}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <template #footer>
        <button class="sc-btn" @click="(show = false) && (friends_selected = [])">
          <span>Regresar</span>
        </button>
        <button class="sc-btn" @click="updateAssistants(friends_selected, match_id)">
          <span>Guardar</span>
        </button>
      </template>
    </the-modal>
  </div>
</template>

<script>
import axios from 'axios';
import VueJwtDecode from "vue-jwt-decode";

export default {
  data() {
    return {
      hours: [
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      sports: [],
      match_hour: "",
      date:"",
      selection: "",
      show: false,
      saved: false,
      friends: [],
      link: "gg.sporstacam.pe",
      field: "",
      direction: "",
      district: "",
      players: [2,4,6,8,10,12,14,16,18,20,22],
      max_players: "",
      match_sport:"",
      uuid: "",
      match_id:"",
      friends_selected: []
    };
  },

  mounted(){
    this.load()
    this.loadFriends()
  },
  methods: {

    loadFriends(){
      fetch(`${process.env.VUE_APP_VERSUS_API}/friend/`, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
          },
        })
        .then((response) => response.json())
        .then((data) => (this.friends = data));
    },

    load(){
      fetch(`${process.env.VUE_APP_VERSUS_API}/sport/`)
        .then((response) => response.json())
        .then((data) => (this.sports = data));
    },

    create() {
      var sport = this.match_sport
      var max_players = this.max_players
      var match_hour = this.match_hour
      

      const body = {
        sports_id: sport,
        max_players: max_players,
        direction: this.direction,
        district: this.district,
        match_date: this.date,
        hour: match_hour,
        pitch_image: null,
        privacy: this.selection,
      }

      const url = `${process.env.VUE_APP_VERSUS_API}/match/`

      axios.post(url, body, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
          },
        })
      .then((res) => {
          console.log(res);
          this.match_sport = "",
          this.max_players = "",
          this.match_hour = "",
          this.date = "",
          this.direction = "",
          this.district = "",
          this.pitch_image = "",
          this.selection = "",
          this.show = true,
          this.match_id = res.data
      })
      .catch((error) => {
          console.log(error);
        });
    },
    
    handleChange(friend_id, event) {
      if (event.target.checked) {
        this.friends_selected.push(friend_id)
        console.log(this.friends_selected)
      } else {
        for (var i = 0; i < this.friends_selected.length ; i++){
          if(this.friends_selected[i] === friend_id){
              for (var j = i; j < this.friends_selected.length - 1; j++){
              this.friends_selected[j] = this.friends_selected[j+1]
            }
            this.friends_selected.pop()
            }
          }
          console.log(this.friends_selected)
        }
    },

    updateAssistants(friends, match_id) {

      for (var i = 0; i < friends.length; i++){
        const body = {
        user_uid: friends[i],
        match_id: match_id
      };
      const url = `${process.env.VUE_APP_VERSUS_API}/match/`;

      axios
        .put(url, body)
        .then((res) => {
          console.log(res);
          this.show = false
          this.friends_selected = []
          this.$router.push("/mymatches");
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
  },
};
</script>

<style scoped>
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf4;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-search {
  width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;
}

.btn {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.rd-btn {
  display: flex;
  position: relative;
  margin: 20px;
  margin-left: 160px;
  gap: 50px;
}

the-modal :deep(.modal-container) {
  width: auto;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}
</style>