<template>
  <div class="container">
    <div class="login-box">
      <div class="logo">
        <img src="@/assets/SC_Logo_White.png" />
      </div>
      <form @submit.prevent="login">
        <input
          class="sc-input"
          v-model="email"
          type="email"
          placeholder="Email"
          autocomplete="username"
          required
        />
        <input
          class="sc-input"
          v-model="password"
          type="password"
          placeholder="Password"
          autocomplete="current-password"
          required
        />
        <div class="form-error" v-if="error">
          {{ error }}
        </div>
        <button class="sc-btn"><span>Sign In</span></button>
      </form>
    </div>
    <video autoplay muted loop>
      <source src="@/assets/video.mp4" type="video/mp4" />
    </video>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },
  methods: {
    login() {
      const body = {
        email: this.email,
        password: this.password,
      };
      const url = `${process.env.VUE_APP_LOGIN_API}/login`;
      axios
        .post(url, body)
        .then((res) => {
          sessionStorage.setItem(
            "FirebaseToken",
            JSON.stringify(res.data.token).slice(1, -1)
          );
          this.$router.push("/home");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.error = "Invalid email or password.";
          } else {
            this.error = "Please try again later.";
          }
        });
    },
  },
};
</script>

<style scoped>
video {
  position: fixed;
  z-index: -1;
}

@media (min-aspect-ratio: 16/9) {
  video {
    width: 100%;
    height: auto;
  }
}
@media (max-aspect-ratio: 16/9) {
  video {
    width: auto;
    height: 100%;
  }
}
@media (max-width: 767px) {
  video {
    display: none;
  }
  .container {
    background: url("@/assets/poster.png");
    background-size: cover;
  }
}

.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.login-box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 250px;
  height: 350px;
  background-color: #020609;
  background-image: linear-gradient(180deg, #020609 10%, #092a3c 100%);
  background-size: cover;
  border-radius: 20px;
}

form {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

form input {
  text-align: center;
}
.form-error {
  color: white;
  font-size: 10px;
  margin: 5px;
}

.logo img {
  width: 250px;
}

.sc-input{
  width: 200px; 
}

@media only screen and (min-width: 600px) {
  /*Tablets */

  .login-box {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 300px;
    height: 400px;
    background-color: #020609;
    background-image: linear-gradient(180deg, #020609 10%, #092a3c 100%);
    background-size: cover;
    border-radius: 20px;
  }

  form {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .sc-input{
    width: 250px;
  }

  form input {
    text-align: center;
  }
  .logo img {
  width: 300px;
}
}

@media only screen and (min-width: 768px) {
  /* PC*/
  .login-box {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 400px;
    height: 500px;
    background-color: #020609;
    background-image: linear-gradient(180deg, #020609 10%, #092a3c 100%);
    background-size: cover;
    border-radius: 20px;
  }
  form {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  form input {
    text-align: center;
  }

  .sc-input{
    width: 300px;
  }

  .logo img {
  width: 350px;
}
}
</style>
