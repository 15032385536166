import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "./pages/LoginPage.vue";
import HomePage from "./pages/HomePage.vue";
import SearchPage from "./pages/SearchPage.vue";
import FriendsPage from "./pages/FriendsPage.vue";
import PichangasPage from "./pages/PichangasPage.vue";
import FriendPage from "./pages/FriendPage.vue";
import CreatePichangaPage from "./pages/CreatePichangaPage.vue";
import MatchPage from "./pages/MatchPage.vue";
import GroupPage from "./pages/GroupPage.vue";
import ProfilePage from "./pages/ProfilePage.vue"

const router = createRouter({
    history: createWebHistory(),
    routes: [
      { path: "/", redirect: "/home" },
      { path: "/login", component: LoginPage, meta: { requiresUnauth: true, hideBars: true}},
      { path: "/home", component: HomePage, meta: { requiresAuth: true, hideBars: false} },
      { path: "/match/:match_id", component: MatchPage, meta: { requiresAuth: true, hideBars: false} },
      { path: "/search", component: SearchPage, meta: { requiresAuth: true, hideBars: false} },
      { path: "/friends", component: FriendsPage, meta: { requiresAuth: true, hideBars: false} },
      { path: "/friend/:id", component: FriendPage, meta: { requiresAuth: true, hideBars: false} },
      { path: "/mymatches", component: PichangasPage, meta: { requiresAuth: true, hideBars: false} },
      { path: "/creatematch", component: CreatePichangaPage, meta: { requiresAuth: true, hideBars: false} },
      { path: "/group/:group_id", component: GroupPage, meta: { requiresAuth: true, hideBars: false} },
      { path: "/myprofile/:user_uid", component: ProfilePage, meta: { requiresAuth: true, hideBars: false} },
      { path: '/:notFound(.*)', redirect: "/" }
    ],
})

router.beforeEach(function(to, _, next) {
    if (to.meta.requiresAuth && !sessionStorage.getItem("FirebaseToken")) {
      next('/login');
    } else if (to.meta.requiresUnauth && sessionStorage.getItem("FirebaseToken")) {
      next('/home');
    } else {
      next();
    }
  });
  
  export default router;