<template>
  <div class="page">
    <h1>Buscar Partidos</h1>
    <div class="content">
      <form @submit.prevent="search">
        <div class="form-search">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01"
                >Buscar</label
              >
            </div>
            <select
              class="custom-select"
              id="inputGroupSelect01"
              v-model="search_type"
              v-show="selection === 'sport'"
            >
              <option value="">Todas...</option>
              <option
                v-for="sport in sports"
                :key="sport"
                :value="sport.sports_id"
              >
                {{ sport.sport }}
              </option>
            </select>
            <input
              type="date"
              v-model="search_type"
              v-show="selection === 'date'"
              style="width: 70%"
            />
          </div>
          <button class="sc-btn"><span>Buscar</span></button>
        </div>
        <div class="rd-btn">
          <div>
            <input
              type="radio"
              name="selection"
              v-model="selection"
              value="sport"
            />
            <label>Deporte</label><br />
          </div>
          <div>
            <input
              type="radio"
              name="selection"
              v-model="selection"
              value="date"
            />
            <label>Fecha</label><br />
          </div>
        </div>
      </form>
      <div class="match">
          <h6 v-show="matches.length === 0">No hay partidos disponibles..</h6>
          <div v-for="match in matches" :key="match">
            <div
              class="match-card"
              :style="{
                backgroundImage: `url(${require('@/assets/' +
                  match.sports_id +
                  '.png')})`,
              }"
            >
              <div class="info">
                <h2>{{ match.sport }}</h2>
                <h2>{{ match.direction }}</h2>
              </div>
              <button class="btn-card" @click="toMatch(match.match_id)">Ver Más</button>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
//import axios from 'axios';

export default {
  data() {
    return {
      sports: [],
      selection: "sport",
      matches: [],
      table: "",
      search_type: "",
    };
  },

  mounted() {
    this.loadSports();
    this.load();
  },

  methods: {
    load() {
      fetch(`${process.env.VUE_APP_VERSUS_API}/match/all`)
        .then((response) => response.json())
        .then((data) => (this.matches = data));
    },

    loadSports() {
      fetch(`${process.env.VUE_APP_VERSUS_API}/sport/`)
        .then((response) => response.json())
        .then((data) => (this.sports = data));
    },

    search() {
      var selected = this.search_type;
      if (selected) {
        if (this.selection === "sport") {
          fetch(
            `${process.env.VUE_APP_VERSUS_API}/match/searchS/${this.search_type}`
          )
            .then((response) => response.json())
            .then((data) => (this.matches = data));
        } else {
          console.log(this.search_type)
          fetch(`${process.env.VUE_APP_VERSUS_API}/match/searchD/${this.search_type}`)
            .then((response) => response.json())
            .then((data) => (this.matches = data));
        }
      } else {
        this.load();
      }
    },
    toMatch(match_id) {
      this.$router.push("/match/" + match_id);
    },
  },
};
</script>

<style scoped>
.rd-btn {
  display: flex;
  position: relative;
  margin: 20px;
  margin-left: 160px;
  gap: 50px;
}
.detalle {
  cursor: pointer;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf4;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.form-search {
  display: flex;
  gap: 20px;
}


.match {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 228px;
  height: 120px;
  gap: 20px;
}


.date-card {
  width: 90px;
  height: 90px;
  margin-left: 120px;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 20px;
  align-items: center;
}

.date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 50px;
  /* identical to box height */

  color: #000000;
}

.hour {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  color: #c20000;
}
</style>