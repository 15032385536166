<template>
  <div class="body">
    <nav class="menu">
      <ul class="nav">
        <li>
          <a><font-awesome-icon icon="fa-solid fa-bars" /></a>
          <ul>
            <li>
              <router-link :to="'/home'">Inicio</router-link>
            </li>
            <li>
              <router-link :to="'/myprofile/' + user.user_uid" v-if="user.nickname">{{
                user.nickname
              }}</router-link>
              <router-link :to="'/myprofile/' + user.user_uid" v-if="!user.nickname"
                >{{ user.name }} {{ user.lname }}</router-link
              >
            </li>
            <li><router-link :to="'/search'">Buscar Partidos</router-link></li>
            <li><router-link :to="'/friends'">Amigos</router-link></li>
            <li>
              <router-link :to="'/mymatches'">Mis Partidos</router-link>
            </li>
            <li><a @click="logout">Cerrar Sesión</a></li>
          </ul>
        </li>
      </ul>
      <a><font-awesome-icon icon="fa-solid fa-users" /></a>
      <router-link :to="'/'"
        ><img src="@/assets/SC_Logo_White.png"
      /></router-link>
      <img
        class="img-profile rounded-circle"
        src="@/assets/undraw_profile.svg"
        v-if="!user.image_profile"
      />
      <form action="">
        <input
          class="sc-input"
          type="text"
          placeholder="¿Cuándo quieres jugar?"
        />
      </form>
    </nav>
    <nav>
      <ul class="nav-desktop">
        <li>
          <router-link :to="'/home'">Inicio</router-link>
        </li>
        <li>
          <router-link :to="'/myprofile/' + user.user_uid" v-if="user.nickname">{{
            user.nickname
          }}</router-link>
          <router-link :to="'/myprofile/' + user.user_uid" v-if="!user.nickname"
            >{{ user.name }} {{ user.lname }}</router-link
          >
        </li>
        <li><router-link :to="'/search'">Buscar Partidos</router-link></li>
        <li><router-link :to="'/friends'">Amigos</router-link></li>
        <li>
          <router-link :to="'/mymatches'">Mis Partidos</router-link>
        </li>
        <li><a @click="logout">Cerrar Sesión</a></li>
      </ul>
    </nav>
  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  data() {
    return {
      user: "",
    };
  },

  mounted() {
    this.getName();
  },

  methods: {

    getName() {
      if(sessionStorage.getItem("FirebaseToken")){
        fetch(`${process.env.VUE_APP_VERSUS_API}/user/`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
        },
      })
        .then((response) => response.json())
        .then((data) => (this.user = data));
      }
    },

    logout() {
      sessionStorage.removeItem("FirebaseToken");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.menu {
  background-color: rgb(4, 35, 89);
  color: white;
  height: 60px;
  width: 100%;
}

img {
  float: unset;
  height: 60px;
}

.nav li a {
  display: block;
  background-color: rgb(4, 35, 89);
  width: auto;
  position: relative;
  z-index: 3;
}

.nav > li {
  list-style-type: none;
  float: left;
}

.nav li ul {
  position: absolute;
  left: 0px;
  display: none;
  width: 450px;
}
.nav li ul li {
  border: 1px solid white;
}

ul {
  float: left;
}

.nav li:hover > ul {
  display: block;
}

li a {
  height: 60px;
  display: block;
  color: white;
  text-align: center;
  padding: 16px;
  text-decoration: none;
}

li a:hover {
  color: rgb(4, 35, 89);
  background-color: white;
}

a {
  cursor: pointer;
}
.img-profile {
  display: none;
}

.fa-bars {
  width: 150%;
  height: 125%;
}
form {
  display: none;
}
.nav-desktop {
  display: none;
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .menu {
    background-color: rgb(4, 35, 89);
    color: white;
    height: 60px;
    width: 100%;
  }

  img {
    float: unset;
    height: 60px;
  }

  ul {
    list-style-type: none;
  }

  .nav li a {
    display: block;
    background-color: rgb(4, 35, 89);
    width: auto;
    position: relative;
  }
  li a:hover {
    color: rgb(4, 35, 89);
    background-color: white;
  }
  .nav li ul {
    position: absolute;
    display: none;
    width: 450px;
  }

  .nav li ul li {
    border: 1px solid white;
  }
}

@media only screen and (min-width: 768px) {
  /* PC*/
  .menu {
    background-color: rgb(4, 35, 89);
    color: white;
    height: 60px;
    width: 100%;
  }

  img {
    float: unset;
    height: 60px;
    margin-left: 1%;
  }

  ul {
    list-style-type: none;
  }
  nav {
    background: rgb(45, 65, 102);
  }
  .img-profile {
    display: block;
    float: left;
  }
  .fa-bars {
    display: none;
  }

  form {
    display: block;
    float: right;
    padding: 10px;
  }
  .nav-desktop {
    display: block;
    position: relative;
    margin-left: 0;
  }

  .nav-desktop li a {
    display: inline-block;
    background-color: rgb(45, 65, 102);
    position: relative;
    margin-right: 2%;
    z-index: 3;
  }

  .nav-desktop > li {
    list-style-type: none;
    display: inline;
  }

  .nav {
    display: none;
  }
  .body {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
  li a {
    height: 60px;
    color: white;
    text-align: center;
    padding: 15px;
    text-decoration: none;
  }

  li a:hover {
  color: rgb(4, 35, 89);
  background-color: white;
}
ul{
  float: none;
}
}
</style>