<template>
  <div class="page">
    <h1 v-if="!user.nickname">Amigos de {{ user.name }}{{ user.lname }}</h1>
    <h1 v-if="user.nickname">Amigos de {{ user.nickname }}</h1>
    <div class="content">
      <form @submit.prevent="search">
        <div class="form-search">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <label class="input-group-text" for="inputGroupSelect01"
                >Buscar Amigo</label
              >
            </div>
            <input type="text" class="form-control" v-model="search_friend" />
          </div>
          <button class="sc-btn"><span>Buscar</span></button>
        </div>
      </form>
      <div class="container-data">
        <!-- DataTales Example -->
        <div class="card mb-4">
          <div class="card-header py-3">
            <h6 class="m-0 font-weight-bold text-primary">Lista de Amigos</h6>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered" id="dataTable">
                <caption></caption>
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Apodo</th>
                    <th>Rating</th>
                  </tr>
                </thead>
                <tbody v-for="friend in friends" :key="friend">
                  <tr @click="profile(friend.friend_uid)">
                    <td>{{ friend.name }} {{ friend.lname }}</td>
                    <td>{{ friend.nickname }}</td>
                    <td>
                      {{ (friend.num_agree / friend.num_rated) * 5 }} <font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  data() {
    return {
      user: "",
      friends: [],
      search_friend: "",
    };
  },

  mounted() {
    this.getName();
    this.loadFriends();
  },

  methods: {
    getName() {
      fetch(`${process.env.VUE_APP_VERSUS_API}/user/`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
        },
      })
        .then((response) => response.json())
        .then((data) => (this.user = data));
    },

    loadFriends() {
      fetch(`${process.env.VUE_APP_VERSUS_API}/friend/`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
        },
      })
        .then((response) => response.json())
        .then((data) => (this.friends = data));

      console.log(this.friends);
    },

    profile(id) {
      this.$router.push("/friend/" + id);
    },

    search(){
      
      fetch(`${process.env.VUE_APP_VERSUS_API}/friend/search/${this.search_friend}`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
        },
      })
        .then((response) => response.json())
        .then((data) => (this.friends = data));

    }
  },
};
</script>

<style scoped>
td {
  cursor: pointer;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf4;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.form-search {
  display: flex;
  gap: 20px;
  margin-bottom: 50px;
}
</style>