<template>
  <div class="page">
    <div>
      <img src="@/assets/luchito.png" />
    </div>
    <div class="user-info">
      <h3>{{ user.name }} {{ user.lname }}</h3>
      <h4>{{ user.nickname }}</h4>
      <p>{{ ((info.num_agree / info.num_rated) * 5).toFixed(2) }} <font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /><font-awesome-icon icon="fa-solid fa-star" /></p>
    </div>
    <div class="container-data">
      <!-- DataTales Example -->
      <div class="card mb-4">
        <div class="card-header py-3">
          <h6 class="m-0 font-weight-bold text-primary">Descripción</h6>
        </div>
        <textarea
          class="description"
          maxlength="500"
          cols="50"
          rows="10"
          v-model="description"
        ></textarea>
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default"
            >Distrito</span
          >
        </div>
        <input
          type="text"
          class="form-control"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          v-model="district"
          required
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default"
            >Ciudad</span
          >
        </div>
        <input
          type="text"
          class="form-control"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          v-model="city"
          required
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default"
            >Provincia</span
          >
        </div>
        <input
          type="text"
          class="form-control"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          v-model="province"
          required
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default"
            >País</span
          >
        </div>
        <input
          type="text"
          class="form-control"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          v-model="country"
          required
        />
      </div>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="inputGroup-sizing-default"
            >Celular</span
          >
        </div>
        <input
          type="text"
          class="form-control"
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          v-model="phone"
          required
        />
      </div>
      <button class="sc-btn" @click="update"><span>Guardar</span></button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      user: "",
      info: "",
      description: "",
      direction: "",
      district: "",
      city: "",
      country: "",
      phone: "",
      province: "",
    };
  },

  mounted() {
    this.loadUser();
    this.loadInfo();
  },

  methods: {
    async loadUser() {
      await fetch(`${process.env.VUE_APP_VERSUS_API}/user/`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
        },
      })
        .then((response) => response.json())
        .then((data) => (this.user = data));

      this.district = this.user.district;
      this.city = this.user.city;
      this.country = this.user.country;
      this.phone = this.user.mobile;
      this.province = this.user.province;
    },

    async loadInfo() {
      await fetch(`${process.env.VUE_APP_VERSUS_API}/user/info/`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
        },
      })
        .then((response) => response.json())
        .then((data) => (this.info = data));

      this.description = this.info.description;
    },

    update() {
      const body = {
        user_uid: this.$route.params.user_uid,
        district: this.district,
        city: this.city,
        province: this.province,
        country: this.country,
        description: this.description,
      };

      const url = `${process.env.VUE_APP_VERSUS_API}/user`;

      axios
        .put(url, body)
        .then((res) => {
          console.log(res);
          window.alert("Información Actualizada!");
          this.loadUser();
          this.loadInfo();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
img {
  width: 30%;
  border-radius: 100%;
}

.description {
  overflow: scroll;
  resize: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  margin-top: 20px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation)
  > .input-group-append:not(:last-child)
  > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation
  > .input-group-append:nth-last-child(n + 3)
  > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  text-align: center;
  white-space: nowrap;
  background-color: #eaecf4;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e707e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d1d3e2;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sc-btn {
  margin-top: 20px;
  margin-left: 130px;
}
</style>