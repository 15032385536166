<template>
  <div class="page">
    <div class="group">
      <img class="image-group" src="@/assets/luchito.png" /> 
      <div>
      <h1>{{group.group_name}}</h1>
      <p>{{group.group_info}}</p>
    </div>
    </div>
    <div class="container">
      <div class="players">
        <div class="player" v-for="player in players" :key="player">
          <img class="image" src="@/assets/luchito.png" /> 
          <div>{{ player.name }} {{ player.lname }}</div>
        </div>
      </div>
      <div class="message-box">
        <div class="message-container" id="message">
          <div class="messages">
          <div v-for="message in messages" :key="message">
            <div class="message members" v-if="message.user_uid !== uid">
            <p class="sender">{{message.name}} {{message.lname}}</p>
            <p>{{message.message}}</p>
          </div>
          <div class="message user" v-if="message.user_uid === uid">
            <p class="sender">{{message.name}} {{message.lname}}</p>
            <p>{{message.message}}</p>
          </div>
          </div>
        </div>
        </div>
        <div>
          <form @submit.prevent="sendMessage">
            <input type="text" class="sc-input" v-model="msg" required />
            <button class="sc-btn"><span>></span></button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VueJwtDecode from "vue-jwt-decode";

export default {
  data(){
    return {
      players: [],
      uid: '',
      messages: [],
      group: '',
      msg: '',
    }
  },

  mounted(){
    this.loadMembers();
    this.loadMessages();
    this.loadGroup();
  },

  methods:{
    loadMembers(){

      fetch(`${process.env.VUE_APP_VERSUS_API}/group/member/${this.$route.params.group_id}`)
        .then((response) => response.json())
        .then((data) => (this.players = data));
    },

    async loadMessages(){
      await fetch(`${process.env.VUE_APP_VERSUS_API}/group/message/${this.$route.params.group_id}`)
        .then((response) => response.json())
        .then((data) => (this.messages = data));

        var element = document.getElementById("message");
        element.scrollTop = element.scrollHeight;
    },

    loadGroup(){
      fetch(`${process.env.VUE_APP_VERSUS_API}/group/info/${this.$route.params.group_id}`)
        .then((response) => response.json())
        .then((data) => (this.group = data));
    },

    sendMessage(){
      const body = {
        message: this.msg,
        group_id: this.$route.params.group_id,
        user_uid: this.uid
      }

      const url = `${process.env.VUE_APP_VERSUS_API}/group/message`

      axios
      .post(url,body)
      .then((res) => {
          console.log(res);
          this.msg = '';
          this.loadMessages();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
</script>
<style scoped>

.container {
  display: grid;
  grid-template-columns: auto 1fr;
}
.members{
  margin-right: 265px
}

.user{
  margin-left: 210px;
  margin-top: 10px;
}

.message{
  width: 170px;
  color: aliceblue;
  background-color: black;
  border-radius: 20px;
}
.message-box{
  width: 390px;
}

.message-container{
  overflow: scroll;
  border: 1px solid;
}

.sender{
  color:aquamarine;
}

.sc-input{
  border: 1px solid #000000;
  margin: 10px;
  width: 69%;
}

.sc-btn{
  border-radius: 100%;
  width: 20%;
  margin: 10px;
}

form{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid;
  
}

.messages{
  height: 500px;

}
.image {
  width: 36px;
  border-radius: 100%;
}

.players{
  border: 1px solid;
}

.player{
  border: 1px solid;
}

.group{
  display: grid;
  grid-template-columns: auto 1fr;
  margin-right: auto;
  width: 490px;
}

.image-group{
  width: 200px;
  border-radius: 100%;
}
</style>