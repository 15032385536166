<template>
  <div class="page">
    <h1 class="welcome">Mis Partidos</h1>
    <button class="sc-btn" @click="create"><span>Crear Pichanga</span></button>
    <div class="content">
      <div class="container-data">
        <div class="match">
          <h6 v-show="matches.length === 0">No hay partidos disponibles..</h6>
          <div v-for="match in matches" :key="match">
            <div
              class="match-card"
              :style="{
                backgroundImage: `url(${require('@/assets/' +
                  match.sports_id +
                  '.png')})`,
              }"
            >
              <div class="info">
                <h2>{{ match.sport }}</h2>
                <h2>{{ match.direction }}</h2>
              </div>
              <button class="btn-card" @click="toMatch(match.match_id)">Ver Más</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      matches: [],
    };
  },

  mounted() {
    this.load();
  },

  methods: {
    async load() {
      fetch(`${process.env.VUE_APP_VERSUS_API}/match/me/`, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("FirebaseToken"),
        },
      })
        .then((response) => response.json())
        .then((data) => (this.matches = data));
    },

    create() {
      this.$router.push("/creatematch");
    },
    toMatch(match_id) {
      this.$router.push("/match/" + match_id);
    },
  },
};
</script>

<style scoped>
.page {
  margin-top: 0px;
  margin-right: auto;
  align-items: start;
  margin-left: 10px;
}

.sc-btn {
  margin-left: 130px;
}

.welcome {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  margin-left: 130px;
  color: #000000;
}

.match {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 228px;
  height: 120px;
  gap: 20px;
}

.date-card {
  width: 90px;
  height: 90px;
  margin-left: 120px;
  margin-top: 15px;
  background: #ffffff;
  border-radius: 20px;
  align-items: center;
}

.date {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 50px;
  /* identical to box height */

  color: #000000;
}

.hour {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  /* identical to box height */
  color: #c20000;
}

.container-data {
  width: 490px;
  height: 500px;
  overflow: scroll;
}

@media only screen and (min-width: 768px) {
  /* For PC: */
  
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .match {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .container-data {
    width: 100%;
  }
  .content {
    width: 100%;
  }
}
</style>